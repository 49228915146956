<template>
  <div class="container datesBoxCarousel" v-if="dates && dates.length > 0">
    <div class="row">
      <div class="col">
        <template v-if="!usePropDates">
        <h2><span>Fortbildungen</span></h2>
        </template>
        <carousel v-if="dates && dates.length > 0">
          <slide v-for="(date, key) in dates" :key="date.id" itemsToShow="1" autoplay="9000">
            <router-link :to="{ path: (date.dateId !== undefined) ? '/portal/dates/'+date.dateId : '/portal/dates/'+date.id }"><div class="card mb-3 w-100" >
              <img v-if="date.image" class="card-img-top" :src="date.image" :alt="date.title" >
              <img v-else-if="date.image_app" class="card-img-top" :src="date.image_app" :alt="date.title" >

              <img v-else class="card-img-top" src="@/assets/img/fallbackdates_bdfl.jpg" :alt="date.title" >

              <div class="card-body">


                  <h6>{{date.title}}</h6>
                  <p class="mb-1">{{date.catTitle}}</p>




                <div class="row">
                  <div class="col-md-5 col-12"  v-if="date.infos && date.infos.booked_complete">
<div class="quota" v-html="showQuota(date)"></div>

                  </div>
                  <div class="col-md-7 col-12"><p class="text-xs mb-0 text-end"><i class="fa-regular fa-calendar"></i> <template v-if="$filters.formatDate(date.end) === $filters.formatDate(date.start)">
                    {{$filters.formatDate(date.start)}}
                  </template>
                    <template v-else>
                      {{ $filters.formatDateNoYear(date.start) }} -
                      {{ $filters.formatDate(date.end) }}</template></p>
                  </div>
                </div>
                <template v-if="usePropDates">
                  <button class="btn btn-secondary btn-sm mt-3" style="min-width: 75px;"><i class="fa-regular fa-chevron-double-right"></i></button>

                </template>
              </div>
              <template v-if="!usePropDates">
              <div class="card-footer red">
               <i class="fa-solid fa-chevron-right"></i> Info | Anmeldung
              </div>

              </template>
            </div></router-link>
          </slide>
          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </carousel>

      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
export default {
  name: "Datesbox",
  data() {
    return {
      dates: [],
      usePropDates: false
    };
  },
  props: {
    propdates: {
      type: Array,
      required: false
    }
  },
  components: {Carousel,
    Slide,
    Pagination,
    Navigation},
  methods: {
    getDates() {
      let apiurl = this.$store.state.apiUrl;
      axios.get(apiurl + "dates?highlighted=true&limit=5")
        .then((response) => {
          this.dates = response.data.result.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showQuota(termin){
      let quota_icon = '';
      let quota_value = parseInt(termin.infos.booked_complete) / parseInt(termin.quota);
      if (quota_value >= 1) {
        quota_icon = '_voll.svg';
      } else if (quota_value > 0.5) {
        quota_icon = '_hoch.svg';
      } else if (quota_value <= 0.5 && quota_value > 0.0001) {
        quota_icon = '_mittel.svg';
      } else if (quota_value === 0) {
        quota_icon = '_wenig.svg';
      }
      let quota_render = '';
      if(parseInt(termin.infos.booked_complete) >= parseInt(termin.quota)){
        quota_icon = '_voll.svg';
         quota_render = '<div class="users"><img class="quotaIcon" src="img/icons/teilnehmer'+quota_icon+'"/><span class="beforeSlash" style="font-size: 12px;">ausgebucht</span> </div>';
      } else {
        quota_render = '<div class="users"><img class="quotaIcon" src="img/icons/teilnehmer' + quota_icon + '"/><span class="beforeSlash">' + termin.infos.booked_complete + '</span>/<span class="afterSlash">' + termin.quota + '</span> </div>';
      }
      return quota_render;
    }
  },
  mounted() {
    if (this.propdates && this.propdates.length > 0) {
      this.dates = this.propdates;
      this.usePropDates = true;
    } else {
      this.getDates();
    }
  },
  watch: {
    propdates: {
      immediate: true, // Trigger the watcher immediately on component mount
      handler(newDates) {
        if (newDates && newDates.length > 0) {
          this.dates = newDates;
          this.usePropDates = true;
        } else {
          this.getDates();
        }
      }
    }
  }
};
</script>
<style scoped>
</style>
