import {createStore} from "vuex";
import {useToast} from 'vue-toastification'
import router from "@/router";
import store from "@/store/index";
import axios from "axios";
import i18n from '../lang'
import {useRoute} from "vue-router";
const toast = useToast();
import {appInstance} from '@/main.js'
import IndexedDbFunctions from "../assets/js/indexedDbFunctions.js";
import indexedDbFunctions from "../assets/js/indexedDbFunctions.js";

let logger;
export default createStore({
  state: {
    version: '1.1.38',
    dbVersion: 4,
    dbName: 'bdflApp',
    loggedInState: false,
    loadTitle: 'Lade Konfiguration',
    configLoader: {
      loadText: 'Bitte warten...',
      loadPercent: 0,
    },
    // TODO- implement action getApiUrl() - from time2023.git
    apiUrl: "https://api.bdfl.de/",
    // apiUrl: "https://api.sketch.media/",
    menu: null,
    activeItems: {
      task: {},
    },
    NewEntryPortal: {},
    NewEntryForum: {},
    subscribedTasks: [],
    configs: {},
    latestNews: {},
  },
  mutations: {
    setMenu(state, menu) {
      state.menu = menu;
    },
    createStorage(state) {
      IndexedDbFunctions.addObject(state.dbName, 1, "core", {id: state.dbName, version: state.dbVersion});
    },
    saveJson(state) {
      IndexedDbFunctions.backup(state.dbName, state);
    },
    handleApiResponse({commit}, shortResponse) {
      if (shortResponse === undefined || !shortResponse || !shortResponse.data || !shortResponse.data.todo) {
        return false;
      }
      const toast = useToast();
      switch (shortResponse.data.todo.method) {
        case "showMessage":
          let testMessage = shortResponse.data.todo.content;
          if (!i18n.de.errors[testMessage]) {
            toast.error(testMessage);
          } else {
            toast.error(i18n.de.errors[testMessage]);
          }
          break;
        case "logout":
          toast.error(shortResponse.data.todo.content);
          store.commit("logout");
          break;
        default:
          alert("default in handleApiResponse");
      }
    },
    handleApiError(state, customMessage) {
      if(state.feedback === undefined){
        state.feedback = {};
      }
      state.feedback.type = "error";
      state.feedback.render = true;
      state.feedback.messageClass = "error";
      if (customMessage === undefined) {
        state.feedback.message = "Leider hat diese Aktion nicht geklappt. Versuche es erneut!";
      } else {
        state.feedback.message = customMessage;
      }
      setTimeout(function () {
        state.feedback.render = false
      }, 6000);
    },
    changeLoggedIn(state, todo) { // or changeLoggedIn(state)
      let checkSwitch = state.loggedInState;
      if (todo === "toNull") {
        state.loggedInState = null;
        localStorage.removeItem("bdflAppToken");
      } else if (todo === true) {
        state.loggedInState = true;
      } else {
        state.loggedInState = !state.loggedInState; // use with this.$store.commit("changeLoggedIn")
      }
      if (checkSwitch !== state.loggedInState && state.loggedInState === true) {
        // router.push("/");
      }
      if (checkSwitch !== state.loggedInState && state.loggedInState === false) {
        router.push("/login");
      }
    },
    setToken(state, hash) { // or changeLoggedIn(state)
      state.token = !state.loggedInState; // use with this.$store.commit("changeLoggedIn")
      localStorage.setItem("bdflAppToken", hash);
    },
    setLatestNews(state, payload) {

      state.latestNews = payload;
    },
    setAcl(state, acl) { // or changeLoggedIn(state)
      state.acl = acl; // use with this.$store.commit("changeLoggedIn")
    },
    setScope(state, acl) { // or changeLoggedIn(state)
      state.scope = acl; // use with this.$store.commit("changeLoggedIn")
    },
    setUser(state, user) { // or changeLoggedIn(state)
      state.user = user; // use with this.$store.commit("changeLoggedIn")
    },
    logout(state, redirect) {
      state.loggedInState = null;
      state.scope = {scope: null, write: false, loggedOut: true};
      state.acl = null;
      localStorage.removeItem("bdflAppToken");
      if (redirect === undefined) {
        router.push("/signin");
      }
    },
    setFirstTimeLogin(state) {
      state.firstTimeLogin = false;
      localStorage.setItem("firstTimeLogin", "done");
    },
    forceSWupdate(state) {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
          for (let registration of registrations) {
            registration.update();
          }
        })
      }
      if (localStorage.getItem("latest_cache_version") !== null && localStorage.getItem("latest_cache_version") !== 'null') {
        state.publishNewVersion = true;
      } else {
        state.publishNewVersion = false;
      }
    },
    setActiveItem(state, payload) {
      state.activeItems[payload.type] = payload.data;
    },
    setNewEntryForum(state, payload) {
      const route = router.currentRoute.value;
      if(route.params){
      if(payload.issue_id === route.params.id){
        //delete state.NewEntryForum[payload.issue_id];
        return;

      }}
      if (state.NewEntryForum[payload.issue_id] === undefined) {
        state.NewEntryForum[payload.issue_id] = []
      }
      if (payload.comment_id !== undefined) {
        state.NewEntryForum[payload.issue_id].push(payload.comment_id);
      }


      // resort state subscribedTasks to have the newest on top
      let subscribedTasks = state.subscribedTasks;
      let subscribedTasksNew = [];
      for (let i = 0; i < subscribedTasks.length; i++) {
        if (state.NewEntryForum[subscribedTasks[i].id] && state.NewEntryForum[subscribedTasks[i].id].length > 0) {
          subscribedTasksNew.push(subscribedTasks[i]);
        }
      }
      for (let i = 0; i < subscribedTasks.length; i++) {
        if (!state.NewEntryForum[subscribedTasks[i].id] || state.NewEntryForum[subscribedTasks[i].id].length === 0) {
          subscribedTasksNew.push(subscribedTasks[i]);
        }
      }

      state.subscribedTasks = subscribedTasksNew;


    },
    setNewEntryPortal(state, payload) {
      state.NewEntryPortal = payload;
    },
    commitSubscribedTasks(state, payload) {
      state.subscribedTasks = payload;
    },
    setConfig(state, payload) {
      state.configs[payload.key] = payload.data;
    },
  },
  actions: {
    toast({}, payload) {
      toast[payload.type](payload.message);
    },
    toastSuccess({dispatch}, payload) {
      dispatch("toast", {type: "success", message: payload});
    },
    toastWarning({dispatch}, payload) {
      dispatch("toast", {type: "warning", message: payload});
    },
    toastError({dispatch}, payload) {
      dispatch("toast", {type: "error", message: payload});
    },
    toastInfo({dispatch}, payload) {
      dispatch("toast", {type: "info", message: payload});
    },
    copyToClipboard({commit}, copyText) {
      navigator.clipboard.writeText(copyText);
      toast.success(copyText + " kopiert!");
    },
    setNewEntryForum({commit}, payload) {
      commit("setNewEntryForum", payload);
    },
    setNewEntryPortal({commit}, payload) {
      commit("setNewEntryPortal", payload);
    },
    getLatestNews({commit, getters, state}) {
      let apiurl = state.apiUrl;
      // get via axios
      axios.get(apiurl + 'content/search/126')
        .then(response => {
          commit("setLatestNews", response.data.result.data[0])


        })
        .catch(error => {
          console.log(error);
        });
    },
    login({commit, getters, dispatch}, payload) {
      if (appInstance) {
        logger = appInstance.config.globalProperties.$log;
      }
      if (!navigator.onLine) {
        commit("showErrorMessage", "Diese Funktion ist offline nicht verfügbar");
      } else {
        commit("changeLoggedIn", "toNull");
        axios.post(getters.getApiUrl + "login", payload).then(function (response) {
          let shortResponse = response.data.result;
          if (shortResponse.success === true) {
            commit("setToken", shortResponse.hash);
              commit("setAcl", shortResponse.acl);
              commit("setUser", shortResponse.user);
              if (shortResponse.scope !== 'customer' && shortResponse.scope !== 'joomla-superuser' && !shortResponse.user.crm_customer_id) {
                dispatch('toastError', 'Dieser Account ist mit keinem Endkunden verknüpft');
              } else {

                commit("setScope", 'customer');
                commit('changeLoggedIn', true);
                dispatch("loadConfigContries");
                router.push({name: "Dashboard"});

              }

          } else {
            dispatch("handleApiResponse", response);
          }
        }).catch(function (error) {
          dispatch("handleApiResponse", error.response);
        });
      }

    },
    checkToken({commit, getters}) {
      if (appInstance) {
        logger = appInstance.config.globalProperties.$log;
      }
      if (navigator.isOnline === undefined || navigator.isOnline) {
        let token = getters.getToken;
        if (token) {
          axios.post(getters.getApiUrl + "checkToken", {token: token}).then(function (response) {
            let shortResponse = response.data.result;
            if (shortResponse.success === true) {
               commit("setToken", shortResponse.hash);
             commit("setAcl", shortResponse.acl);
              commit("setScope", shortResponse.user.scope);
              commit('changeLoggedIn', true);
              commit("setUser", shortResponse.user);
              if (shortResponse.user.scope === 'customer' || shortResponse.user.scope === 'joomla-superuser') {
                 router.push({name: "Dashboard"});
              } else {
                 commit("logout");
              }
            } else {
              commit("handleApiResponse", response);
            }
          }).catch(function (error) {
            commit("handleApiError", error);
          });
        }
      }
    },
    logout({commit}) {
      commit("logout");
    },
    handleApiError({commit}, error) {
      commit("handleApiError", error);
    },
    handleApiResponse({commit}, response) {
      commit("handleApiResponse", response);
    },
    setTask({commit}, task) {
      if (task === null || task.id === null) {
        commit("setActiveItem", {data: null, type: "task"});
      } else {
        commit("setActiveItem", {data: task, type: "task"});
      }
    },
    setSubscribedTasks({commit}, tasks) {
      commit("commitSubscribedTasks", tasks);
    },
    async loadConfigContries({getters, commit, state}) {
      const headers = {"crmAuth": localStorage.getItem("bdflAppToken")};
      let getUrl = getters.getApiUrl + "config/countries";

      getUrl = getUrl + "?limit=0";
      await axios.get(getUrl, {headers}).then(async (response) => {
        let shortResponse = response.data.result;
        let key = 'countries';

        commit("setConfig", {key: key, data: shortResponse.data});

      }).catch(function () {
      });
    }
  },
  getters: {
    getNewsItem: state => {

      return state.latestNews;
    },
    getToken() {
      return localStorage.getItem("bdflAppToken");
    },
    getUser: state => {
      return state.user;
    },
    checkLogin: state => {
      return state.loggedInState// use with this.$store.getters.checkLogin
    },
    getApiUrl: state => {
      return state.apiUrl;
    },
    getAuthorization() {
      return {"crmAuth": localStorage.getItem("bdflAppToken")};
    },
    getMenu: state => {
      return state.menu;
    },
    taskActive: state => {
      return state.activeItems.task;
    },
    getNewEntryForum: state => {
      return state.NewEntryForum;
    },
    getNewEntryPortal: state => {
      return state.NewEntryPortal;
    },
    getSubscribedTasks: state => {
      return state.subscribedTasks;
    },
    getConfigs: state => {
      return state.configs;
    }
  }
});
